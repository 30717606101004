<template>
  <div class="page_container">
    <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="news_content container">
      <div class="left">
        <div class="tit">
          <h1>“黑洞”图形引擎助力中电建华东院打造智慧城市CIM平台</h1>
          <div class="time">发布时间：2020-8-10 17:20</div>
          <div class="info">
            <p>近日，中国电建集团华东勘测设计研究院有限公司与上海秉匠信息科技有限公司签订“黑洞”BIM+GIS 三维图形引擎采购合同。</p>
            <p class="p_pic">
              <span style="font-size: 14px;"><img src="../../assets/image/news/news_cim01.jpg"></span>
            </p>
            <p>智慧城市CIM平台的研发在海量数据承载、多源异构数据整合、数据可视化、三维轻量化等方面都有较高的要求。“黑洞”图形引擎作为国内屈指可数从底层架构开始自主研发的轻量化BIM+GIS图形引擎，真正做到了国产自主可控，可以将倾斜摄影模型、点云模型、GIS遥感影像等数据与高精度BIM数据集成。同时“黑洞”图形引擎支持同一场景多模型展示，在不损失模型原始精度前提下，可承载任意超大场景模型，并且在网页端实现秒级加载，支持光照、阴影、水面、植被、烘焙等逼真的渲染效果，可解决设计、建造、运维过程中的可视化沟通及分析等问题。经过层层比选， “黑洞”图形引擎最终以其优异的性能得到中电建华东院的青睐，助力中电建华东院智慧城市CIM平台的研发建设。</p>
            <p  class="p_pic">
              <span style="font-size: 14px;"><img src="../../assets/image/news/news_cim02.jpg"></span>
            </p>
            <p>中国电建集团华东勘测设计研究院有限公司（以下简称“华东院”）1954年建院， 是中国电力建设集团的特级企业。名列中国工程设计企业20强、中国承包商80强、中国勘察设计综合实力百强单位，先后荣获全国文明单位、住建部全过程工程咨询试点企业、浙江省首批总承包试点企业、浙江省一带一路示范企业、浙江省国际工程示范企业等。</p>
            <p  class="p_pic">
              <span style="font-size: 14px;"><img src="../../assets/image/news/news_cim03.jpg"></span>
            </p>
            <p>华东院注重以信息化带动技术和管理创新，拥有国际一流的工程数字化业务能力，自2004年率先开展三维数字化设计研究应用以来，研制开发了目前国内第一个专业齐全、功能完备、应用成熟、覆盖基础设施建设全过程，并具有国际领先水平的《工程数字化解决方案》。涵盖工程三维数字化设计、工程设计施工一体化管理、工程全生命周期管理三大平台，实现了全专业、全过程的工程三维数字化设计与应用，是中国工程设计行业全面实现工程设计从二维CAD 向三维数字化协同设计与应用整体跨越的典范。2013年被国家工信部授予“国家级两化融合示范企业”称号。</p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="tit"><h3><font></font>热点内容</h3></div>
        <ul>
          <li v-for="item in hotList" :key="item.id">
            <p><router-link :to="item.link">{{item.name}}</router-link></p>
            <span>{{item.time}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hotList: [
        {id:1,name: '“黑洞”图形引擎助力中电建华东院打造智慧城市CIM平台',time: '2020-11-09',link:'/newsDetails_new_CIM'},
        {id:2,name: '秉匠科技又双叒叕获奖了！',time: '2020-10-28',link:'/newsDetails_new_BIM'},
        {id:3,name: '江苏东交智控科技集团股份有限公司与秉匠科技签订“黑洞”图形引擎采购合同',time: '2020-11-02',link:'/newsDetails_new_dongjiao'},
        {id:4,name: '“黑洞引擎”荣获“上海市第二届BIM技术应用创新大赛”特别创意优秀奖',time: '2020-8-10',link:'/newsDetails_new_hj'}
      ]
    }
  },
  mounted () {
      document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>

<style lang="less" scoped>
.page_container{
  margin-top: 50px;
  .news_content{
    overflow: hidden;
    .left{
      width: 60%;
      height: 100%;
      .tit{
        h1{
          color: #222;
          font-size: 22px;
          font-weight: normal;
          line-height: 30px;
        }
        .time{
          margin: 10px;
          height: 27px;
          border-bottom: 1px #ddd solid;
          font-size: 14px;
          color: #999;
          padding-right: 30px;
        }
      }
      .info{
        padding-top: 32px;
        text-align: center;
        p{
          font-size: 16px;
          color: #222;
          line-height: 28px;
          text-align: left;
          text-indent:2em;
        }
        .p_pic{
          text-indent: 0;
        }
        img{
          margin: 16px 0;
        }
      }
    }
    .right{
      width: 35%;
      height: 100%;
      .tit{
        border-bottom: 1px #444 solid;
        padding-bottom: 14px;
        margin-top: 24px;
        font{
          width: 4px;
          height: 18px;
          background: #222;
          display: inline-block;
          margin-right: 16px;
          position: relative;
          top: 4px;
        }
      }
      ul{
        padding: 12px;
        li{
          line-height: 24px;
          font-size: 16px;
          color: #555;
          border-bottom: 1px #ddd solid;
          padding: 12px 0;
          p{
            a{
              color: #555;
            }
          }
          span{
            display: block;
            font-size: 14px;
            color: #777;
            padding-top: 4px;
          }
        }
        .router-link-active{
          border-bottom: none;
        }
      }
    }
  }
}
</style>
